import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';
import { CustomSnackbarService } from '@shared/components/custom-snackbar/custom-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class DesktopResolverService  {

  constructor(private userService: UserService,
              private router: Router,
              private customSnackbarService: CustomSnackbarService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions = this.userService.userData.selected_customer.permissions;
    const desktopPages = this.userService.desktopPages;

    const navigateTo = (path: string | string[]): boolean => {
      this.router.navigate(Array.isArray(path) ? path : [path]);
      return false;
    };

    if (state.url === '/') {
      if (permissions.includes('dashboard_can_get_new_dashboard')) {
        return navigateTo('dashboard');
      }
      if (desktopPages.length !== 0 && permissions.includes('dashboard_can_get_reports')) {
        return navigateTo('report');
      }
      if (permissions.includes('dashboard_can_get_campaigns')) {
        return navigateTo('campaigns');
      }
      if (permissions.includes('dashboard_can_get_cab')) {
        return navigateTo('cab');
      }
      return navigateTo('settings');
    }

    // Redirect from report routes if there are no reports
    if (desktopPages.length === 0 && state.url.includes('report/')) {
      this.customSnackbarService.showSnackbar('No reports available', 'warning');
      if (permissions.includes('dashboard_can_get_campaigns')) {
        return navigateTo('campaigns');
      }
      return navigateTo('settings');
    }

    // Redirect from '/report' root to the first available report
    if (state.url === '/report') {
      if (permissions.includes('dashboard_can_get_reports') && desktopPages.length > 0) {
        return navigateTo(`/${desktopPages[0].route}`);
      }
      return navigateTo('settings');
    }

    return true;
  }

}

