import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiHttp } from './services/ui-http/ui-http.service';
import { LoadingService } from './services/ui-http/loading.service';
import { UserService } from './services/user/user.service';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { ExcludePipe } from './pipes/exclude/exclude.pipe';
import { KeysPipe } from './pipes/keys/keys.pipe';
import { PbiReportComponent } from './components/pbi-report/pbi-report.component';
import { EllipsisPipe } from './pipes/ellipsis/ellipsis.pipe';
import { MiddleEllipsisPipe } from './pipes/middle-ellipsis/middle-ellipsis.pipe';
import { SendEmailDialogComponent } from './components/send-email-dialog/send-email-dialog.component';
import { SendEmailDialogService } from './services/send-email-dialog.service';
import { ContentEditDialogService } from './services/content-edit-dialog.service';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ContentEditDialogComponent } from './components/content-edit-dialog/content-edit-dialog.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FroalaEditorComponent } from './components/froala-editor/froala-editor.component';
import {NgLetDirective} from '@shared/directives/ng-let.directive';
import { FilterDialogComponent } from '@shared/components/filter-dialog/filter-dialog.component';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { BannerComponent } from '@shared/components/banner/banner.component';
import { BoldPipe } from '@shared/pipes/bold/bold.pipe';
import { SendPhoneDialogService } from '@shared/services/send-phone-dialog.service';
import { SendPhoneDialogComponent } from '@shared/components/send-phone-dialog/send-phone-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  BodySubjectContentEditDialogComponent
} from "@shared/components/body-subject-content-edit-dialog/body-subject-content-edit-dialog.component";

@NgModule({
    declarations: [
        KeysPipe,
        ExcludePipe,
        CapitalizePipe,
        PbiReportComponent,
        EllipsisPipe,
        MiddleEllipsisPipe,
        SendEmailDialogComponent,
        SendPhoneDialogComponent,
        ContentEditDialogComponent,
        ConfirmationDialogComponent,
        FroalaEditorComponent,
        NgLetDirective,
        FilterDialogComponent,
        NgLetDirective,
        BannerComponent,
        BoldPipe,
        BodySubjectContentEditDialogComponent,
    ],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatLegacyCheckboxModule,
    MatTooltipModule,
  ],
    providers: [
        UiHttp,
        LoadingService,
        SendEmailDialogService,
        SendPhoneDialogService,
        ContentEditDialogService,
    ],
    exports: [
        KeysPipe,
        ExcludePipe,
        CapitalizePipe,
        PbiReportComponent,
        EllipsisPipe,
        MiddleEllipsisPipe,
        SendEmailDialogComponent,
        SendPhoneDialogComponent,
        ContentEditDialogComponent,
        FroalaEditorComponent,
        NgLetDirective,
        BannerComponent,
        BoldPipe,
        BodySubjectContentEditDialogComponent,
    ]
})
export class UiSharedModule {
  static forRoot(): ModuleWithProviders<UiSharedModule> {
    return {
        ngModule: UiSharedModule,
        providers: [UserService],
    };
}
}
