import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ContentEditDialogService } from '@shared/services/content-edit-dialog.service';

export interface DialogData {
  defaultText: string;
  customText: string;
  textType: string;
  rtl: boolean;
  maxLength: number;
  customTitle?: string;
  isSenderLoginName?: boolean;
  smishingValidation?: boolean;
  showAutoTranslateCheckBox?: boolean;
  showCharacterLimit?: boolean;
  specialTextValidation?: boolean;
  specialText?: string;
  isCabQuestion?: boolean;
  cabQuestionAnswer?: boolean;
}

@Component({
  selector: 'app-content-edit-dialog-component',
  templateUrl: './content-edit-dialog.component.html',
  styleUrls: ['./content-edit-dialog.component.scss']
})
export class ContentEditDialogComponent implements OnInit {

  public textType = 'default';
  public showAutoTranslateCheckBox = false;
  public autoTranslateCheckBoxState: boolean = false;
  public showLengthWarning: boolean = false;
  public showNoSpecialTextWarning: boolean = false;
  public validationResult: { isValid: boolean; errors: { [key: string]: boolean } };

  constructor(
    public dialogRef: MatDialogRef<ContentEditDialogComponent>,
    private contentEditDialogService: ContentEditDialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.onCustomTextChange();
  }

  close(): void {
    this.dialogRef.close();
  }

  focusTextarea(textarea) {
    setTimeout(function() {
      textarea.focus();
    }, 1);
  }


  inputIsValid(): boolean {
    this.validationResult = this.contentEditDialogService.validateData(this.data);
    return this.validationResult.isValid;
  }

  onCustomTextChange(): void {
    const originalLength = this.data.defaultText.length;
    const customLength = this.data.customText.length;
    // Check if custom text exceeds 150% of the original text
    if (customLength > originalLength * 1.5) {
      this.showLengthWarning = true;
    } else {
      this.showLengthWarning = false;
    }
  }

}

