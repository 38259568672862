<div class="dialog-close" cdkFocusInitial (click)="dialogRef.close()"></div>

<h1 mat-dialog-title>
  <ng-container *ngIf="data.customTitle; else defaultTitle">{{data.customTitle}}</ng-container>
  <ng-template #defaultTitle>Edit content</ng-template>
</h1>

<div id="edit-dialog" mat-dialog-content>
  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="data.textType">

    <mat-radio-button (click)="focusTextarea(originalTextArea)" value="default" color="primary">
      Use original
    </mat-radio-button>

    <textarea #originalTextArea
              class="default-text"
              [(ngModel)]="data.defaultText"
              [readOnly]="true"
              (click)="data.textType = 'default'"
              [ngClass]="{'rtl': data.rtl }"></textarea>

    <mat-radio-button (click)="focusTextarea(customTextArea)" value="custom" color="primary">
      Use custom
    </mat-radio-button>

    <textarea #customTextArea
              class="custom-text"
              placeholder="Type here..."
              [maxlength]="data.maxLength"
              [(ngModel)]="data.customText"
              [readOnly]="data.textType==='default'"
              (click)="data.textType = 'custom'"
              (input)="onCustomTextChange()"
              (paste)="onCustomTextChange()"
              [ngClass]="{'rtl': data.rtl }"></textarea>
    <div class="char-count" *ngIf="data.showCharacterLimit">
      Characters: {{data.customText?.length}}
      <img src="assets/svg/info-icon-purple.svg"
           class="pointer margin-l-5"
           matTooltipClass="custom-tooltip"
           matTooltip="Editing is limited to {{data.maxLength}} characters to reserve space for dynamic content.">
    </div>
    <div *ngIf="data.textType === 'custom' && !inputIsValid() && data.isSenderLoginName" class="error-message">
        The sender login name must not contain spaces, non-ASCII characters, or be empty.
    </div>
    <div *ngIf="data.textType === 'custom' && data.isCabQuestion" class="custom-error-message">
        <img src="assets/svg/shield_purple.svg" alt="" class="margin-r-10">
        Phrase the question so that the correct answer is “{{data.cabQuestionAnswer.toString() | titlecase}}”.
    </div>
    <div *ngIf="data.textType === 'custom' && showLengthWarning" class="custom-error-message">
      <img src="assets/svg/shield_purple.svg" alt="" class="margin-r-10">
      Your text is a bit long. Shorter text is more likely to be read by employees.
    </div>
    <div *ngIf="data.textType === 'custom' && !inputIsValid() && data.smishingValidation" class="error-message">
      <ng-container *ngIf="validationResult.errors.noSmishingLink">Please add the following text where the phishing link should be: <span ngNonBindable>"{{link}}"</span>.</ng-container>
      <ng-container *ngIf="validationResult.errors.moreThanOneSmishingLink">You can only have one phishing link in a smishing simulation.</ng-container>
    </div>
  </mat-radio-group>

  <div class="cab-change-notice">
    <span *ngIf="data.customText && data.textType === 'default'">
      * Selecting to revert to default text will delete the custom text
    </span>
  </div>

  <div class="language-changes ui-layout-row align-center" *ngIf="!data.showAutoTranslateCheckBox">
    <img src="/assets/svg/info-warning.svg">
    <p>Note that changes will apply only to the selected language</p>
  </div>
  <div class="ui-layout-row align-center margin-b-16" *ngIf="data.showAutoTranslateCheckBox">
    <mat-checkbox class="dashboard-checkbox"
                  [(ngModel)]="autoTranslateCheckBoxState"
                  name="autotranslate"
                  color="primary">
      Auto-translate text to all available languages
    </mat-checkbox>
  </div>

</div>

<div mat-dialog-actions class="ui-layout cab-change-bottom" ui-layout-align="end">
  <div>
    <button mat-button class="dashboard-button action" (click)="close()">Cancel</button>
    <button mat-button [mat-dialog-close]="{'text': data.customText, 'textType': data.textType, 'auto_translate': autoTranslateCheckBoxState}"
                       [disabled]="(data.textType === 'custom' && !inputIsValid())
                       || (data.textType === 'custom' && !data.customText?.length)
                       || (data.customText?.length > data.maxLength)"
                       class="dashboard-button confirm focused">
      Save
      <ng-container *ngIf="data.showAutoTranslateCheckBox && autoTranslateCheckBoxState"> & Translate</ng-container>
    </button>
  </div>
</div>

