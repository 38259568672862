import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LoadingService } from './loading.service';
import { UserService } from '@shared/services/user/user.service';

import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { forceLogout } from '@app/auth/store/auth.actions';
import { Store } from '@ngrx/store';
import { CustomSnackbarService } from '@shared/components/custom-snackbar/custom-snackbar.service';


@Injectable({
  providedIn: 'root'
})
export class ServerUrlInterceptor implements HttpInterceptor {

  public error: BehaviorSubject<any>;

  constructor(private customSnackbarService: CustomSnackbarService,
              private loadingService: LoadingService,
              private router: Router,
              private store: Store,
              private userService: UserService) {

    this.error = new BehaviorSubject<any>(null);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // don't show spinner when silent: true
    if (!req.params.get('silent') && req.body?.silent !== true) {
      this.loadingService.pushRequest();
    }
    const branding = this.userService.currentBranding.id;
    const cloneData = { withCredentials: true };
    if (branding) {
      if (req.method === 'GET') {
        cloneData['params'] = req.params.append('branding', branding);
      } else {
        cloneData['body'] = {...req.body, branding: branding };
      }
    }

    const duplicate = req.clone(cloneData);

    return next.handle(duplicate).pipe(
      tap((res: HttpEvent<any>) => {
        if (res instanceof HttpResponse) {
          this.loadingService.popRequest();
          return res;
        }
      }, err => {
        this.loadingService.popRequest();
        if (err instanceof HttpErrorResponse) {
          // skip error handling for endpoint that has ski_errors property
          if (req.body?.skip_errors) {
            return throwError(err);
          }
          if (err.status === 0) {
            this.customSnackbarService.showSnackbar('Unknown Error', 'warning');
            console.error('error: ', err);
            return EMPTY;
          }
          if (err.status === 402) { // status 402 means only one thing - 2FA required
            if (this.router.url !== '/' && this.router.url !== '/login') {
              this.userService.returnUrl = this.router.url;
            }
            localStorage.removeItem('customer');
            this.router.navigate(['/tfa']);
            return EMPTY;
          }
          if (err.status === 403) { // no permission
            const warn = 'Your session has expired or you don\'t have permissions to do that. Please log in again.';
            const err_msg = err.error.link_error || warn;
            this.customSnackbarService.showSnackbar(err_msg, 'warning');
            if (this.router.url !== '/') {
              this.userService.returnUrl = this.router.url;
            }
            localStorage.removeItem('customer');
            this.store.dispatch(forceLogout());
            this.router.navigate(['/login']);
            return EMPTY;
          }
        }

        let details = '';
        if (err.error instanceof Blob) {
          details = this.blobToString(err.error);
        } else {
          for (const key in err.error) {
            if (err.error.hasOwnProperty(key) && key !== 'token' && typeof (err.error) !== 'string') {
              if (err.status == 400 && key === 'non_field_errors') {
                return throwError(err);
              }
              if (key !== 'non_field_errors' && err.status !== 406) {
                details += `${key}: ${err.error[key]}`;
              } else {
                details += `${err.error[key]}`;
              }
            }
          }
        }

        const msg = details.length > 0 ? details : 'Server error';
        this.customSnackbarService.showSnackbar(msg, 'warning');
        return Observable.create(err);
      })
    );
  }

  blobToString(b) {
    let url, xml;
    url = URL.createObjectURL(b);
    xml = new XMLHttpRequest();
    xml.open('GET', url, false);
    xml.send();
    URL.revokeObjectURL(url);
    return xml.responseText;
  }
}
